
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["close"]
})
export default class MBaseModal extends Vue {
  close() {
    this.$emit("close");
  }
}
